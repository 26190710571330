const routes = [{
        path: ["/", "/home"],
        exact: true,
        component: "Home",
    },
    {
        path: "/quote",
        exact: true,
        component: "Quote",
    },
    {
        path: "/name",
        exact: true,
        component: "PetName",
    },
    {
        path: "/pet",
        exact: true,
        component: "Pet",
    },
    {
        path: "/you",
        exact: true,
        component: "Owner",
    },
    {
        path: "/price",
        exact: true,
        component: "Pricing",
    }
];

export default routes;