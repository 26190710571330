import { createSlice } from "@reduxjs/toolkit";

const packageSlice = createSlice({
  name: "packages",
  initialState: {
    packages: "",
    lt: "",
    tl: "",
    mb: "",
  },
  reducers: {
    addPackages(state, action) {
      var theplans = [];

      const test = Object.entries(action.payload["Lt"]).forEach((key) => {
        key[1].name = key[0];
        theplans.push(key[1]);
        console.log(key);
      });

      Object.entries(action.payload["TL"]).forEach((key) => {
        key[1].name = key[0];
        theplans.push(key[1]);
      });

      Object.entries(action.payload["MB"]).forEach((key) => {
        key[1].name = key[0];
        theplans.push(key[1]);
      });

      state.packages = Object.values(action.payload["Lt"])
        .concat(Object.values(action.payload["TL"]))
        .concat(Object.values(action.payload["MB"]));
      state.lt = state.packages.slice(0, 3);
      state.tl = state.packages.slice(3, 6);
      state.mb = state.packages.slice(6, 9);
    },
  },
});

export const packageActions = packageSlice.actions;

export default packageSlice;
