import { createSlice, configureStore } from '@reduxjs/toolkit';

import packageSlice from './package-slice';

const dogFormSlice = createSlice({
    name: 'dog',
    initialState: {
        pet_name: '',
        dob: '',
        postcode: '',
        breed: '',
        breeds: ''
    },
    reducers: {
        addPetName(state, action) {
            state.pet_name = action.payload;
        },
        addPostCode(state, action) {
            state.postcode = action.payload;
        },
        addDOB(state, action) {
            state.dob = action.payload;
        },
        addBreed(state, action) {
            state.breed = action.payload;
        },
        addBreeds(state, action) {
            const sortable = Object.entries(action.payload)
                .sort(([, a], [, b]) => b - a)
                .reduce((r, [k, v]) => ({...r, [k]: v }), {});

            const sorted = Object.keys(sortable);
            state.breed = sorted.shift();
            state.breeds = sorted;
        }
    }
});

const store = configureStore({
    reducer: { dog: dogFormSlice.reducer, packages: packageSlice.reducer }
})

export const dogActions = dogFormSlice.actions;

export default store;